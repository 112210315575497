.discover-button{
    align-items: center;
    -webkit-backdrop-filter: blur(32px);
    backdrop-filter: blur(32px);
    background: linear-gradient(83.59deg,#fcfcfd 36.52%,rgba(252,252,253,.83) 98.8%);
    border-radius: 50%;
    box-shadow: 0 40px 64px -32px hsla(0,0%,6%,.1);
    box-shadow: inset 0 0 0 2px var(--n6);
    display: flex;
    height: 80px;
    justify-content: center;
    width: 80px;
}